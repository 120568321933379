import React, { useState } from 'react';
import Accordion from "react-bootstrap/Accordion";

export default function MonthTodo({ months, current, allMonths, formatNumber }) {
  const [activeKey, setActiveKey] = useState(null);

  const handleAccordionClick = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };

  return (
    <div className='block-month'>
      <div className='block-month-all'>
        {months
          .sort((a, b) => b.month - a.month)
          .map((month, index) => (
            <Accordion key={month.month} activeKey={activeKey} onSelect={handleAccordionClick}>
              <Accordion.Item eventKey={index.toString()}>
                <Accordion.Header>{allMonths[month.month - 1]}:  <span className='total-year'>{month.total}{current}{` (${month.entries.length})`}</span></Accordion.Header>
                <Accordion.Body className='fix-month'>
                  {month.entries
                    .sort((a, b) => a.date - b.date)
                    .map(record => (
                      <div key={record.id} className='block-view'>
                        <div className='block-left'>
                          <div
                            className="block-date fix-date">{formatNumber(record.date.getDate())}.{formatNumber(record.date.getMonth() + 1)}</div>
                          <div className='block-view-title'>{record.title}{current}</div>
                        </div>
                      </div>
                    ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
      </div>
    </div>
  )
}

