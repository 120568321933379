import React, { useState } from 'react';
import Accordion from "react-bootstrap/Accordion";
import MonthTodo from './MonthTodo';
import BarChartComponent from './BarChartComponent';
import AreaChartComponent from './AreaChart';
import download from '../../img/download.png';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStackOverflow } from '@fortawesome/free-brands-svg-icons';


const YearsTodo = ({ todos, current, chart, setChart, formatNumber, viewAll, setViewAll }) => {
  const [activeYear, setActiveYear] = useState(null);
  const allMonths = ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"];

  const handleAccordionClick = (year) => {
    setActiveYear(activeYear === year ? null : year);
  };

  const showAllView = () => {
    setViewAll(!viewAll);
    localStorage.setItem('viewAll', !viewAll);
  }

  // const chartChange = () => {
  //   const newChartValue = !chart;
  //   setChart(newChartValue);
  //   localStorage.setItem('chart', newChartValue.toString());
  // }

  const chartChange = (type) => {
    setChart(type);
    localStorage.setItem('chart', type);
  }

  const currentName = {
    '₴': `грн.`,
    '€': 'евро',
    '$': 'долар',
    'zł': 'злотих'
  }

  function generateExcelReport(data, yearTrue) {
    const fileName = `звіт_${yearTrue}.xlsx`;
    const combinedEntries = []
    data.forEach(item => {
      if (item.year === yearTrue) {
        item.months.forEach(month => {
          month.entries.forEach(entry => {
            const date = new Date(entry.date);
            const formattedDate = `${formatNumber(date.getDate())}.${formatNumber(date.getMonth() + 1)}.${date.getFullYear()}`;
            combinedEntries.push({
              'Дата': formattedDate,
              'Сума': `${entry.title} ${currentName[current]}`
            });
          });
        });
        const ws = XLSX.utils.json_to_sheet(combinedEntries);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Звіт');
        XLSX.writeFile(wb, fileName);
      }
    })
  }

  return (
    <div className='block-year-all fix-block-year'>
      {todos.length > 0 && <div className="block-more">
        <p className="bar-chart">
          Вид:
          <span className={chart === 'AreaChart' ? 'chart-btn-active' : 'chart-btn'} onClick={() => chartChange('AreaChart')}>Список</span>|
          <span className={chart === 'BarChart' ? 'chart-btn-active' : 'chart-btn'} onClick={() => chartChange('BarChart')}>Графік</span>|
          <span className={chart === 'LineChart' ? 'chart-btn-active' : 'chart-btn'} onClick={() => chartChange('LineChart')}>Лінія</span>

        </p>
        <FontAwesomeIcon icon={faStackOverflow} className={`show-all-view-btn ${viewAll ? 'blue-view' : ''}`} onClick={showAllView}/>
      </div>}
      {todos.map(record => (
        <Accordion
          key={record.year}
          activeKey={activeYear === record.year ? record.year : null}
          onSelect={handleAccordionClick}>
          <Accordion.Item eventKey={record.year}>
            <Accordion.Header>{record.year} рік. Всього:<span className='total-year'>{record.total}{current}{` (${record.months.reduce((total, month) => {
              return total + month.entries.length;
            }, 0)})`}</span></Accordion.Header>
            <Accordion.Body>
              {chart === 'BarChart' && (
                <div style={{ width: '100%', height: '250px' }}>
                  <BarChartComponent months={record.months} current={current} allMonths={allMonths} />
                </div>
              )}
              {chart === 'LineChart' && (
                <div style={{ width: '100%', height: '250px' }}>
                  <AreaChartComponent months={record.months} current={current} allMonths={allMonths} />
                </div>
              )}
              {chart === 'AreaChart' && (
                <div>
                  <MonthTodo months={record.months} current={current} allMonths={allMonths} formatNumber={formatNumber} />
                  <div className="download-block">
                    <p>Завантажити звіт:</p>
                    <img src={download} alt="download" className='download-img' onClick={() => generateExcelReport(todos, record.year)} />
                  </div>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))
      }

    </div >
  )
}

export default YearsTodo