import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { updateDoc, doc } from "firebase/firestore";
import none from '../../img/none.png';
import Modal from 'react-bootstrap/Modal';

const MainMenu = ({ setCurrent, setTheme, selectCurrency, setSelectCurrency, db, imageMapping, theme, uid, logOut, avatar, version}) => {
  const navBlock = Object.keys(imageMapping);
  const [smShow, setSmShow] = useState(false);

  async function handleSelect(eventKey, e) {
    try {
      const selectedText = e.target.innerText;
      const settingDocRef = doc(db, "setting", uid);
      await updateDoc(settingDocRef, {
        current: eventKey,
        select: selectedText
      });
      setSelectCurrency(selectedText);
      setCurrent(eventKey);
    } catch (error) {
      console.error("Помилка при оновленні даних у Firestore: ", error);
    }
  }

  async function handleSelectTheme(eventKey) {
    try {
      const settingDocRef = doc(db, "setting", uid);
      await updateDoc(settingDocRef, {
        theme: eventKey,
      });
      setTheme(eventKey);
    } catch (error) {
      console.error("Помилка при оновленні даних у Firestore: ", error);
    }
  }

  return (
    <div className="main-all-block">
      <div className='nav-all-seting'>
        <Nav variant="pills" activeKey="1" onSelect={handleSelectTheme} className='nav'>
          <NavDropdown title={theme ? `Тема ${theme}` : `Тема None`} id="nav-dropdown-theme">
            {navBlock.map(themes => (
              <NavDropdown.Item key={themes} eventKey={themes}>
                <img src={imageMapping[themes]} alt={themes} className='img-nav-drop' />
                {themes}
              </NavDropdown.Item>
            ))}
            <NavDropdown.Divider />
            <NavDropdown.Divider />
            <NavDropdown.Divider />
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey=""><img src={none} alt='none' className='img-nav-drop' />None</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav variant="pills" activeKey="1" onSelect={handleSelect} className='nav'>
          <NavDropdown title={selectCurrency || 'Злотий'} id="nav-dropdown">
            <NavDropdown.Item eventKey="₴">Гривня</NavDropdown.Item>
            <NavDropdown.Item eventKey="€">Євро</NavDropdown.Item>
            <NavDropdown.Item eventKey="$">Долар</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="zł">Злотий</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <div className='about-block'>
          <button onClick={() => setSmShow(true)} className="about">
            Інфо
          </button>
          <Modal
            size="sm"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Інформація:
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Назва: NUMERARE</p>
              <p>Автор: Sergii Tsymbaliuk</p>
              <p>Опис: Программа для підрахунку прибутку</p>
              <br />
              <p>bild {version}</p></Modal.Body>
          </Modal>
        </div>
      </div>
      <div className='logout-block'>
          <img src={avatar} alt="ava" className='ava' />
          <button className="login-name" onClick={logOut}>Вийти</button>
      </div>
    </div>
  )
}

export default MainMenu