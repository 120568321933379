import Header from './Components/Header/Header';
import TodoList from './Components/TodoList/TodoList';
import AddTodo from './Components/AddTodo/AddTodo';
import YearsTodo from './Components/MonthTodo/YearsTodo';
import MainMenu from './Components/MainMenu/MainMenu';
import theme1 from './img/1.jpg';
import theme2 from './img/2.jpg';
import theme3 from './img/3.jpg';
import theme4 from './img/4.jpg';
import theme5 from './img/5.jpg';
import theme6 from './img/6.jpg';
import theme7 from './img/7.jpg';
import theme8 from './img/8.jpg';
import theme9 from './img/9.jpg';
import theme10 from './img/10.jpg';
import theme11 from './img/11.jpg';
import theme12 from './img/12.jpg';
import theme13 from './img/13.jpg';
import theme14 from './img/14.jpg';
import theme15 from './img/15.jpg';
import theme16 from './img/16.jpg';
import loding from './img/loding.gif';
import bgLoding from './img/loginBG.png';
import './style.css';
import "firebase/firestore";
import "firebase/compat/firestore";
import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore, getDoc, setDoc, doc } from "firebase/firestore";
import { ProgressBar } from 'react-loader-spinner';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import React, { useState, useEffect, useMemo } from 'react';
import SignIn from './Components/SignIn/SignIn';

function App() {
  const [todo, setTodo] = useState([]);
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
  const [current, setCurrent] = useState('zł');
  const [selectCurrency, setSelectCurrency] = useState('');
  const [theme, setTheme] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState('');
  const [email, setEmail] = useState('');
  const [uid, setUid] = useState('');
  const [avatar, setAvatar] = useState('');
  const [chart, setChart] = useState('AreaChart');
  const [showAll, setShowAll] = useState(true);
  const [showPositive, setShowPositive] = useState(false);
  const [showNegative, setShowNegative] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  let version = '0.73';

  const imageMapping = {
    'Quilt': theme1,
    'Orange': theme2,
    'Soft': theme3,
    'Lines': theme4,
    'Green': theme5,
    'Blue': theme6,
    'Light': theme7,
    'Red': theme8,
    'Honey': theme9,
    'Cloud': theme10,
    'Pink': theme11,
    'Wall': theme12,
    'Rouge': theme13,
    'Cute': theme14,
    'Rose': theme15,
    'Moon': theme16,
  }

  const firebaseConfig = {
    apiKey: "AIzaSyCZwC1uyS0YwUR2xr0yfkB3u0GgzNAh90Y",
    authDomain: "liza-adbf9.firebaseapp.com",
    projectId: "liza-adbf9",
    storageBucket: "liza-adbf9.appspot.com",
    messagingSenderId: "528158875212",
    appId: "1:528158875212:web:cd810ca5d330082ef65ee9"
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const querySnapshot = await getDocs(collection(db, !uid ? 'default' : uid));
        let userSettingData = {};
        if (uid) {
          const settingDocRef = doc(db, "setting", uid);
          const settingDocSnapshot = await getDoc(settingDocRef);
          if (settingDocSnapshot.exists()) {
            const settingData = settingDocSnapshot.data();
            userSettingData = {
              current: settingData.current,
              theme: settingData.theme,
              select: settingData.select,
            };
          } else {
            const defaultSettings = {
              current: 'zł',
              theme: '',
              select: 'Злотий',
            };
            await setDoc(settingDocRef, defaultSettings);
            userSettingData = defaultSettings;
          }
        }
        setCurrent(userSettingData.current || '');
        setTheme(userSettingData.theme || '');
        setSelectCurrency(userSettingData.select || '');
        const newTodo = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          newTodo.push({
            id: doc.id,
            title: data.title,
            date: data.date.toDate(),
          });
        });
        const savedChartValue = localStorage.getItem('chart');
        setChart(savedChartValue);
        setTodo(newTodo);
        setIsLoading(false);
        setValue(localStorage.getItem('email'));
        setEmail(localStorage.getItem('name'));
        setUid(localStorage.getItem('uid'));
        setAvatar(localStorage.getItem('ava'));
        setShowAll(localStorage.getItem('showAll') === 'true');
        setShowPositive(localStorage.getItem('showPositive') === 'true');
        setShowNegative(localStorage.getItem('showNegative') === 'true');
        setViewAll(localStorage.getItem('viewAll') === 'true');
      } catch (error) {
        console.error("Помилка отримання даних з Firestore: ", error);
      }
    }
    fetchData();
  }, [db, uid]);

  const groupedByYearMonthTodos = useMemo(() => {
    const groupedByYearMonth = {};

    todo.forEach(item => {
      if (!viewAll) {
        if (parseInt(item.title) < 0) {
          return
        }
      }
      const year = item.date.getFullYear();
      const month = item.date.getMonth() + 1;
      if (!groupedByYearMonth[year]) {
        groupedByYearMonth[year] = {
          year: year,
          total: 0,
          months: [],
        };
      }
      const monthEntry = groupedByYearMonth[year].months.find(entry => entry.month === month);
      if (!monthEntry) {
        groupedByYearMonth[year].months.push({
          month: month,
          total: 0,
          entries: [],
        });
      }
      groupedByYearMonth[year].months.forEach((entry) => {
        if (entry.month === month) {
          entry.total += parseInt(item.title);
          entry.entries.push({
            id: item.id,
            title: item.title,
            date: item.date,
          });
        }
      });
      groupedByYearMonth[year].total += parseInt(item.title);
    });
    return Object.values(groupedByYearMonth);
  }, [todo, viewAll]);

  const handleClick = () => {
    signInWithPopup(auth, provider).then((data) => {
      setValue(data.user.email);
      localStorage.setItem('email', data.user.email);
      localStorage.setItem('name', data.user.displayName);
      localStorage.setItem('uid', data.user.uid);
      localStorage.setItem('ava', data.user.photoURL);
      localStorage.setItem('chart', 'AreaChart');
      setUid(data.user.uid);
      setEmail(data.user.displayName);
      setAvatar(data.user.photoURL)
    })
  }

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  }

  function formatNumber(number) {
    return number < 10 ? `0${number}` : number;
  }

  return (
    <div className="body">
      {/* <div className="showfall"></div> */}
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className='container'>

        <div className='block'>
          {isLoading ? (
            <>
              <img src={bgLoding} alt="bgLoding" className='bg-loding-img' />
              <div className='block-loding'>
                <img src={loding} alt="loding" className='loding-img' />
                <div className="loading">
                  <ProgressBar
                    height="70"
                    width="80"
                    ariaLabel="progress-bar-loading"
                    wrapperStyle={{}}
                    wrapperClass="progress-bar-wrapper"
                    borderColor='#accec3'
                    barColor='#fff'
                  />
                </div>
              </div>
            </>
          ) : (
            value ? (
              <>
                {theme && <img src={imageMapping[theme]} alt={theme} className='img-block' />}
                <div className="version">{version}</div>
                <p className='email-name'>{email}</p>
                <Header
                  todo={todo} setTodo={setTodo}
                  db={db} isAnimationPlaying={isAnimationPlaying}
                  current={current} showAll={showAll}
                  showPositive={showPositive} showNegative={showNegative} />
                <AddTodo todo={todo} setTodo={setTodo} db={db} setIsAnimationPlaying={setIsAnimationPlaying} uid={uid} />
                <TodoList todo={todo} setTodo={setTodo} db={db} current={current} uid={uid}
                  formatNumber={formatNumber} showAll={showAll} setShowAll={setShowAll}
                  showPositive={showPositive} setShowPositive={setShowPositive}
                  showNegative={showNegative} setShowNegative={setShowNegative} />
                {groupedByYearMonthTodos && <YearsTodo todos={groupedByYearMonthTodos} current={current}
                  chart={chart} setChart={setChart} formatNumber={formatNumber} viewAll={viewAll} setViewAll={setViewAll} />}
                <MainMenu setCurrent={setCurrent} setTheme={setTheme} theme={theme}
                  selectCurrency={selectCurrency} setSelectCurrency={setSelectCurrency} db={db}
                  imageMapping={imageMapping} email={email} uid={uid} logOut={logOut} avatar={avatar}
                  version={version} />
              </>
            ) : (
              <>
                <img src={theme7} alt='sign' className='img-block' />
                <div className="version">{version}</div>
                <SignIn handleClick={handleClick} />
              </>
            ))}
        </div>
      </div>
    </div>
  );
}

export default App;