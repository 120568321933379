import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

const BarChartComponent = ({ months, current, allMonths }) => {

  const data = [];
  const currentName = {
    '₴' : `UAH`,
    '€' : 'EUR',
    '$' : 'USD',
    'zł': 'PLN'
  }

  months.forEach((month) => {
    const monthIndex = month.month;
    const monthName = allMonths[month.month - 1];
    const monthAmount = month.total;
    const monthlyObject = { index: monthIndex, name: monthName, total: monthAmount };
    data.push(monthlyObject);
  });

  data.sort((a, b) => {
    const monthA = a.index;
    const monthB = b.index;
    if (monthA < monthB) return -1;
    if (monthA > monthB) return 1;
    return 0;
  });

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        width="100%"
        height="100%"
        fontSize={14}
        data={data}
        margin={{
          top: 15,
          right: 20,
          left: -10,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name"/>
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="total" stackId="a" name={`Сума за місяць (${currentName[current]})`} fill="#51a6c2">
          <LabelList dataKey="total" position="top" fontSize={12} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarChartComponent