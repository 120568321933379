import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const AreaChartComponent = ({months, current, allMonths}) => {
  const data = [];
  const currentName = {
    '₴' : `UAH`,
    '€' : 'EUR',
    '$' : 'USD',
    'zł': 'PLN'
  }

  months.forEach((month) => {
    const monthIndex = month.month;
    const monthName = allMonths[month.month - 1];
    const monthAmount = month.total;
    const monthlyObject = { index: monthIndex, name: monthName, total: monthAmount };
    data.push(monthlyObject);
  });

  data.sort((a, b) => {
    const monthA = a.index;
    const monthB = b.index;
    if (monthA < monthB) return -1;
    if (monthA > monthB) return 1;
    return 0;
  });

  return (
    <ResponsiveContainer width="100%" height={250}>
    <AreaChart data={data} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
    <defs>
      <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor="#51a6c2" stopOpacity={0.8} />
        <stop offset="95%" stopColor="#51a6c2" stopOpacity={0} />
      </linearGradient>
    </defs>
    <XAxis dataKey="name" />
    <YAxis />
    <CartesianGrid strokeDasharray="3 3" />
    <Tooltip />
    <Area type="monotone" dataKey="total" stroke="#438da5" fillOpacity={1} name={`Сума за місяць (${currentName[current]})`} fill="url(#colorTotal)" />
  </AreaChart>
  </ResponsiveContainer>
  )
}

export default AreaChartComponent
