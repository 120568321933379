import React from 'react';
import google from '../../img/google.png';
import miSign from '../../img/mi-sign.png';
import meaw from '../../img/meaw.png'

const SignIn = ({handleClick}) => {

  return (
    <div className='sign-in'>
      <img src={meaw} alt="meaw" className='meaw animate-meaw'/>
      <img src={miSign} alt="mimi" className='mimi animate-mimi'/>
      <h1 className='todo-h1 todo-h1-fix'>NUMERARE</h1>
      <button onClick={handleClick} className='sign-in-btn'><img src={google} alt="google" className='google-img'/>Continue with Google</button>
    </div>
  )
}

export default SignIn