import React, { useState } from 'react';
import lapa from '../../img/lapa.png';
import { deleteDoc, doc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faArrowsRotate, faArrowDownShortWide } from '@fortawesome/free-solid-svg-icons';

const TodoList = ({ todo, setTodo, db, current, uid, formatNumber, showAll, setShowAll, showPositive, setShowPositive, showNegative, setShowNegative }) => {
  const date = new Date();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();
  const daysOfWeek = ["Неділя", "Понеділок", "Вівторок", "Середа", "Четвер", "П'ятниця", "Субота"];
  const months = ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"];
  const [isSortingAscending, setIsSortingAscending] = useState(true);

  const showAllTodos = () => {
    setShowAll(true);
    setShowPositive(false);
    setShowNegative(false);
    localStorage.setItem('showAll', 'true');
    localStorage.setItem('showPositive', 'false');
    localStorage.setItem('showNegative', 'false');
  }

  const showPositiveTodos = () => {
    setShowAll(false);
    setShowPositive(true);
    setShowNegative(false);
    localStorage.setItem('showAll', 'false');
    localStorage.setItem('showPositive', 'true');
    localStorage.setItem('showNegative', 'false');
  }

  const showNegativeTodos = () => {
    setShowAll(false);
    setShowPositive(false);
    setShowNegative(true);
    localStorage.setItem('showAll', 'false');
    localStorage.setItem('showPositive', 'false');
    localStorage.setItem('showNegative', 'true');
  }

  const deleteTodo = (id) => {
    const newTodo = todo.filter(item => item.id !== id);
    setTodo(newTodo);
    const todoRef = doc(db, uid, id);
    deleteDoc(todoRef);
  }

  const check = todo.filter(item => item.date.getMonth() === currentMonth);

  const formatCurrency = (amount) => {
    return `${amount}${current}`;
  }

  const toggleSorting = () => {
    setIsSortingAscending(!isSortingAscending);
  }

  const groupedTodos = todo.reduce((acc, item) => {
    const key = `${item.date.toDateString()}_${item.title < 0 ? 'negative' : 'positive'}_${item.date.getFullYear()}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  const filterCondition = (item) => {
    if (showAll) return true;
    if (showPositive) return item.title >= 0;
    if (showNegative) return item.title < 0;
    return true;
  };

  return (
    <div className="date-block">
      <div className='block-sort-date-all'>
        {check.length > 0 && (
          <div className="sort-block">
            <FontAwesomeIcon icon={faArrowsRotate} className={`show-btn ${showAll ? 'blue' : ''}`} onClick={showAllTodos}/>
            <FontAwesomeIcon icon={faPlus} className={`plus-btn ${showPositive ? 'blue' : ''}`} onClick={showPositiveTodos}/>
            <FontAwesomeIcon icon={faMinus} className={`minus-btn ${showNegative ? 'blue' : ''}`} onClick={showNegativeTodos}/>
            <FontAwesomeIcon icon={faArrowDownShortWide} className='sort-btn' onClick={toggleSorting}/>
          </div>
        )}
        <div className='date-block-all'>{`${daysOfWeek[date.getDay()]} ${formatNumber(date.getDate())} ${months[date.getMonth()]} ${date.getFullYear()}`}
        </div>
      </div>
      {check.length > 0 && (
        <div className='block-view-all'>
          <img src={lapa} alt="lapa" className="lapa-img" />
          {Object.keys(groupedTodos)
            .filter(dateKey => {
              const [dateString, sign, year] = dateKey.split('_');
              const dateYear = parseInt(year);
              return dateYear === currentYear && groupedTodos[dateKey][0].date.getMonth() === currentMonth;
            })
            .sort((a, b) => {
              const dateA = new Date(a.split('_')[0]);
              const dateB = new Date(b.split('_')[0]);
              const monthA = dateA.getMonth();
              const monthB = dateB.getMonth();

              if (isSortingAscending) {
                if (monthA === monthB) {
                  return dateA - dateB;
                }
                return monthA - monthB;
              } else {
                if (monthA === monthB) {
                  return dateB - dateA;
                }
                return monthB - monthA;
              }
            })
            .map(dateKey => {
              const items = groupedTodos[dateKey];
              const groupedItems = [];
              for (let i = 0; i < items.length; i += 5) {
                groupedItems.push(items.slice(i, i + 5));
              }
              return (
                groupedItems.map((group, index) => (
                  filterCondition(group[0]) && (
                    <div key={`${dateKey}-${index}`} className={`block-view ${group.some(item => item.title < 0) ? 'negative-value' : 'positive-value'}`}>
                      <div key={index} className='block-left'>
                        <div className="block-date">{`${formatNumber(group[0].date.getDate())}.${formatNumber(group[0].date.getMonth() + 1)}`}</div>
                        <div className='block-view-title'>{group.map(item => formatCurrency(item.title)).join(',')}</div>
                      </div>
                      <div className='todo-view-btn'>
                        <div className='btn-delete' onClick={() => deleteTodo(items[items.length-1].id)}>&#10006;</div>
                      </div>
                    </div>
                  )
                ))
              );
            })}
        </div>
      )}
    </div>
  )
}

export default TodoList;
