import { collection, addDoc } from "firebase/firestore";
import React, { useState } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uk from "date-fns/locale/uk";

const AddTodo = ({ todo, setTodo, db, setIsAnimationPlaying, uid }) => {
  const [value, setValue] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  registerLocale("uk", uk);
  setDefaultLocale("uk");
  let docRef;

  async function saveTodo(event) {
    event.preventDefault();
    try {
      if (value.trim() !== '') {
        setDate(date);
        docRef = await addDoc(collection(db, uid), {
          title: value,
          date: startDate
        });
        setTodo(
          [
            ...todo, {
              id: docRef.id,
              title: value,
              date: startDate
            }
          ]
        );
        setIsAnimationPlaying(true);
        setTimeout(() => {
          setIsAnimationPlaying(false);
        }, 1000);
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    setValue('')
  }

  function handleInputChange(event) {
    const inputValue = event.target.value;
    if (/^-?\d*$/.test(inputValue)) {
      setValue(inputValue);
    }
  }

  return (
    <form onSubmit={saveTodo} className='todo-add-all'>
      <input className='todo-input' placeholder='Введи суму' value={value} onChange={handleInputChange} />
      <div className="date-controls">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          className="todo-date-edit"
          dateFormat="dd/MM/yyyy"
          withPortal 
          onTouchStart={(e) => {
            e.preventDefault();
          }}/>
      </div>
      <button type="submit" onClick={saveTodo} className='todo-add'>Додати</button>
    </form>
  )
}

export default AddTodo